import { useEffect, useState } from 'react';
import './styles/App.css';

import { Layout } from 'antd';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';

import Sidebar from './components/Sidebar';
import TopHeader from './components/TopHeader';
import NoMatch from './pages/NoMatch';
import debuglog from './scripts/debugLog';

import axios from 'axios';
import InitConnectForm from './components/InitConnectForm';
import DashboardPage from './pages/DashboardPage';
import TablePage from './pages/TablePage';
import { getAgentConfig } from './scripts/ConnectAuth';

import './i18n/i18n';

const { Content } = Layout;

function App() {
  const navigate = useNavigate();

  enum InitState {
    Unauthorized, // Has not yet passed initConnect (authorisation with Amazon Connect)
    Initialising, // Attempting to validate subscription / agent / version
    Skinning, // Initialises all customisations (theming, layout, widget configs)
    QueueList, // Initialises list of all available queues and iterates widget configs for queues in use
    QueueData, // Get queue data
    Complete, // Initialisation complete
  }

  const [initState, setInitState] = useState<InitState>(InitState.Unauthorized); // The current state of initialisation (used to sequentially run tasks)

  const [authorizationError, setAuthorizationError] = useState<string>(''); // Used when there is a connection error
  const [connectInstanceARN, setConnectInstanceARN] = useState(''); // REQUIRED popoulated by agent-streams
  const [agentUsername, setAgentUsername] = useState(''); // Used to save skinning per agent
  const [accountID, setAccountID] = useState(''); // Used to check subscription status
  const [authorized, setAuthorized] = useState(false); // Used to check subscription status

  // Check if authorized on startup:
  useEffect(() => {
    if (authorized) {
      navigate('/dashboard');
    } else {
      navigate('/');
    }
  }, [authorized]);

  async function initConnect(instanceURL: string, ssoURL: string) {
    await setInitState(InitState.Initialising);

    if (!instanceURL.endsWith('/')) instanceURL += '/';

    debuglog('URL Values:', instanceURL, ssoURL);

    if (global.localStorage) {
      global.localStorage.removeItem('connectPopupManager::connect::loginPopup');
    }

    try {
      var agentConfig = await getAgentConfig(instanceURL, ssoURL);
    } catch (error) {
      debuglog('Start Connect Streams Initialisation Error', error);
      await setAuthorizationError('No Connection');
    }

    debuglog('Agent Config Result', agentConfig);

    var connectInstanceARN = agentConfig.agentStates[0].agentStateARN.split('/')[0] + '/' + agentConfig.agentStates[0].agentStateARN.split('/')[1];
    await setConnectInstanceARN(connectInstanceARN);

    var accountId = agentConfig.agentStates[0].agentStateARN.split(':')[4];
    await setAccountID(accountId);

    await setAgentUsername(agentConfig.username);

    // Check subscription:
    //await checkSubscription(connectInstanceARN, true);

    // All checks passed!
    debuglog('All checks passed!', connectInstanceARN, accountId, agentConfig.username);
    await setAuthorized(true);

    // Start PCS initialisation:
    await setInitState(InitState.Skinning);
  }

  // Set up custom axios instance with authorization interceptors:
  const axiosInstance = axios.create();

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<MainLayout user={accountID} authorized={authorized} />}>
          <Route index element={<InitConnectForm initConnect={initConnect} loading={initState === InitState.Initialising} />} />

          <Route path="/dashboard" element={<DashboardPage connectInstanceARN={connectInstanceARN} axiosInstance={axiosInstance} />} />
          <Route path="/table" element={<TablePage connectInstanceARN={connectInstanceARN} axiosInstance={axiosInstance} />} />

          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

function MainLayout(props: { user: string; authorized: boolean }) {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <TopHeader user={props.user} />
      <Layout>
        {props.authorized && <Sidebar />}
        <Content style={{ margin: '16px 24px 16px 104px' }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}

export default App;
