import * as React from 'react';

import GridLayout from 'react-grid-layout';
import 'react-resizable/css/styles.css';
import useResizeObserver from 'use-resize-observer';
import '../styles/widgetgrid.css';

import { LoadingOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { RawSurveyResponse } from '../scripts/API/APITypes';
import debuglog from '../scripts/debugLog';
import { WidgetConfig, WidgetType } from './widgets/common/WidgetTypes';
import CompletionWidget, { CompletionWidgetConfig } from './widgets/completion/CompletionWidget';
import CountWidget, { CountWidgetConfig } from './widgets/count/CountWidget';
import LeaderboardWidget, { LeaderboardWidgetConfig } from './widgets/leaderboard/LeaderboardWidget';
import ScoreWidget, { ScoreWidgetConfig } from './widgets/score/ScoreWidget';

const { Text } = Typography;

const greenColor = '#30BF78';
const amberColor: string = '#fa8c16';
const redColor: string = '#f5222d';

interface WidgetGridProps {
  widgetConfigs: WidgetConfig[];
  layout: GridLayout.Layout[];
  onLayoutChange: (layout: GridLayout.Layout[]) => void;
  responseData: RawSurveyResponse[];
  editing: boolean;
  onEditWidgetClicked: any;
  queueList: { name: string; id: string }[];
  currentQueues: string[][];
  queueSelected: (queue: string) => void;
  initComplete: boolean;
}

function WidgetGrid(props: WidgetGridProps) {
  const { ref, width = 1 } = useResizeObserver<HTMLDivElement>();
  const [appeared, setAppeared] = React.useState(false);
  const widgetAppearDelay = 50;

  React.useEffect(() => {
    setAppeared(false);
    return;
  }, [props.widgetConfigs]);

  function buildWidget(config: WidgetConfig, index: number) {
    switch (config.type) {
      case WidgetType.Count:
        return (
          <div key={config.widgetid} style={!appeared ? { animationDelay: index * widgetAppearDelay + 'ms' } : {}} data-grid={{ x: 0, y: 0, w: 2, h: 2, minW: 2, minH: 2 }}>
            <CountWidget
              id={config.widgetid}
              config={config.config as CountWidgetConfig}
              responseData={props.responseData}
              editing={props.editing}
              onEditWidgetClicked={props.onEditWidgetClicked}
              amberColor={amberColor}
              redColor={redColor}
              greenColor={greenColor}
              queueList={[]}
              triggerAlarm={function (id: string, alarmSound: string): void {
                // throw new Error('Function not implemented.');
              }}
              dismissAlarm={function (id: string): void {
                // throw new Error('Function not implemented.');
              }}
            />
          </div>
        );

      case WidgetType.Score:
        return (
          <div key={config.widgetid} style={!appeared ? { animationDelay: index * widgetAppearDelay + 'ms' } : {}} data-grid={{ x: 0, y: 0, w: 2, h: 2, minW: 2, minH: 2 }}>
            <ScoreWidget
              id={config.widgetid}
              config={config.config as ScoreWidgetConfig}
              responseData={props.responseData}
              editing={props.editing}
              onEditWidgetClicked={props.onEditWidgetClicked}
              amberColor={amberColor}
              redColor={redColor}
              greenColor={greenColor}
              queueList={[]}
              triggerAlarm={function (id: string, alarmSound: string): void {
                // throw new Error('Function not implemented.');
              }}
              dismissAlarm={function (id: string): void {
                // throw new Error('Function not implemented.');
              }}
            />
          </div>
        );

      case WidgetType.Leaderboard:
        return (
          <div key={config.widgetid} style={!appeared ? { animationDelay: index * widgetAppearDelay + 'ms' } : {}} data-grid={{ x: 0, y: 0, w: 4, h: 4, minW: 4, minH: 4 }}>
            <LeaderboardWidget
              id={config.widgetid}
              config={config.config as LeaderboardWidgetConfig}
              responseData={props.responseData}
              editing={props.editing}
              onEditWidgetClicked={props.onEditWidgetClicked}
            />
          </div>
        );

      case WidgetType.Completion:
        return (
          <div key={config.widgetid} style={!appeared ? { animationDelay: index * widgetAppearDelay + 'ms' } : {}} data-grid={{ x: 0, y: 0, w: 4, h: 4, minW: 4, minH: 4 }}>
            <CompletionWidget
              id={config.widgetid}
              config={config.config as CompletionWidgetConfig}
              responseData={props.responseData}
              editing={props.editing}
              onEditWidgetClicked={props.onEditWidgetClicked}
            />
          </div>
        );
    }
  }

  function buildGrid() {
    var items = props.widgetConfigs.map((key, i) => buildWidget(key, i));
    if (!appeared)
      window.setTimeout(() => {
        setAppeared(true);
      }, props.widgetConfigs.length * widgetAppearDelay + 400);
    debuglog(`Grid built: `, items);
    return items;
  }

  return (
    <div ref={ref}>
      <div>
        {!props.initComplete ? (
          <div
            style={{
              width: '100%',
              padding: '48px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <LoadingOutlined style={{ fontSize: '48px', opacity: 0.2 }} />

            <Text style={{ textAlign: 'center' }}>Fetching data...</Text>
          </div>
        ) : (
          <GridLayout
            layout={props.layout}
            onLayoutChange={props.onLayoutChange}
            className="layout"
            cols={12}
            rowHeight={60}
            width={width}
            isResizable={props.editing}
            isDraggable={props.editing}
            compactType={null}
            preventCollision
          >
            {buildGrid()}
          </GridLayout>
        )}
      </div>
    </div>
  );
}

export default WidgetGrid;
