import { useState } from 'react';

import { Form, Modal, Radio } from 'antd';

import WidgetSettingsForm from '../components/widgets/common/WidgetSettingsForm';
import { WidgetConfig, WidgetType } from '../components/widgets/common/WidgetTypes';
import { CountType, CountWidgetConfig } from '../components/widgets/count/CountWidget';
import { LeaderboardWidgetConfig } from '../components/widgets/leaderboard/LeaderboardWidget';
import { ScoreWidgetConfig } from '../components/widgets/score/ScoreWidget';
import { AgentDataModel, QuestionDataModel, QueueDataModel } from '../scripts/API/APITypes';

import debuglog from '../scripts/debugLog';

// Localisation:
import { useTranslation } from 'react-i18next';
import { CompletionWidgetConfig } from '../components/widgets/completion/CompletionWidget';
import { LeaderboardType } from '../components/widgets/leaderboard/LeaderboardWidgetSettings';

interface MetricSelectionProps {
  isVisible: boolean;
  handleOk: (para: WidgetConfig) => void;
  handleCancel: any;
  queueList: { [key: string]: QueueDataModel };
  questionList: { [key: string]: QuestionDataModel };
  agentList: { [key: string]: AgentDataModel };
  widgetConfigs: WidgetConfig[];
  availableAlarms: string[];
  openAlarmModal: () => void;
  availableAgents: string[];
}

function AddWidgetModal(props: MetricSelectionProps) {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [widgetType, setWidgetType] = useState<WidgetType | null>(null);

  function getNextIndex(type: string): number {
    let i = 0;
    while (props.widgetConfigs.some((wc) => wc.widgetid === type + i)) {
      debuglog(`${type + i} exists!`);
      i++;
    }

    return i;
  }

  async function handleOk() {
    try {
      const values = await form.validateFields();

      debuglog('Success', values);
      let config: WidgetConfig | null = null;

      const i = getNextIndex(values.type);

      switch (values.type) {
        case 'count':
          config = {
            widgetid: values.type + i,
            type: WidgetType.Count,
            config: {
              countType: values.counttype || CountType.Completed,
              queues: values.queues || [],
              questions: values.questions || [],
              agents: values.agents || [],
              label: values.label,
              amberThreshold: values.metricamber || 20,
              redThreshold: values.metricred || 40,
              showAsGreen: values.showasgreen || false,
              amberSound: values.metricambersound || '',
              redSound: values.metricredsound || '',
              blink: values.metricblink || false,
            } as CountWidgetConfig,
          };
          break;

        case 'score':
          config = {
            widgetid: values.type + i,
            type: WidgetType.Score,
            config: {
              queues: values.queues || [],
              questions: values.questions || [],
              agents: values.agents || [],
              label: values.label,
              amberThreshold: values.metricamber || 20,
              redThreshold: values.metricred || 40,
              showAsGreen: values.showasgreen || false,
              amberSound: values.metricambersound || '',
              redSound: values.metricredsound || '',
              blink: values.metricblink || false,
            } as ScoreWidgetConfig,
          };
          break;

        case 'leaderboard':
          config = {
            widgetid: values.type + i,
            type: WidgetType.Leaderboard,
            config: {
              label: values.label,
              type: values.leaderboardtype || LeaderboardType.Average,
              queues: values.queues || [],
              questions: values.questions || [],
              minimumScore: values.minimumscore || 0,
              maximumScore: values.maximumscore || 0,
              minimumResponses: values.minimumresponses,
            } as LeaderboardWidgetConfig,
          };
          break;

        case 'completion':
          config = {
            widgetid: values.type + i,
            type: WidgetType.Completion,
            config: {
              label: values.label,
              queues: values.queues || [],
            } as CompletionWidgetConfig,
          };
          break;
      }

      if (!config) return;

      debuglog('Adding widget', config);
      props.handleOk(config);

      form.resetFields();
      setWidgetType(null);
    } catch (errorInfo) {
      debuglog('Failed:', errorInfo);
    }
  }

  return (
    <Modal
      title={t('addwidgetmodal.title')}
      onOk={handleOk}
      onCancel={props.handleCancel}
      okText={t('addwidgetmodal.oktext')}
      visible={props.isVisible}
      destroyOnClose={true}
      forceRender={true}
      width="640px"
    >
      <Form form={form} layout="vertical" name="addWidget">
        <Form.Item
          label={t('addwidgetmodal.widgettype.label')}
          name="type"
          rules={[
            {
              required: true,
              message: t('addwidgetmodal.widgettype.required'),
            },
          ]}
        >
          <Radio.Group
            onChange={(v) => {
              debuglog('type', v);

              switch (v.target.value) {
                case 'count':
                  setWidgetType(WidgetType.Count);
                  break;
                case 'score':
                  setWidgetType(WidgetType.Score);
                  break;
                case 'leaderboard':
                  setWidgetType(WidgetType.Leaderboard);
                  break;
                case 'completion':
                  setWidgetType(WidgetType.Completion);
                  break;
              }
            }}
          >
            <Radio.Button key="count" value="count">
              Response Count
            </Radio.Button>
            <Radio.Button key="score" value="score">
              Average Score
            </Radio.Button>
            <Radio.Button key="leaderboard" value="leaderboard">
              Agent Leaderboard
            </Radio.Button>
            <Radio.Button key="completion" value="completion">
              Completion Rate
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        {WidgetSettingsForm({
          widgetType: widgetType,
          queueList: props.queueList,
          questionList: props.questionList,
          agentList: props.agentList,
          currentWidget: null,
          availableAlarms: props.availableAlarms,
          openAlarmModal: props.openAlarmModal,
        })}
      </Form>
    </Modal>
  );
}

export default AddWidgetModal;
