import { SettingOutlined } from '@ant-design/icons';
import { Column, ColumnConfig } from '@ant-design/plots';
import { Card, Empty, Typography } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RawSurveyResponse } from '../../../scripts/API/APITypes';
import debuglog from '../../../scripts/debugLog';
import WidgetButtons from '../common/WidgetButtons';
import { WidgetDataConfig } from '../common/WidgetTypes';

const { Text } = Typography;

interface ColumnModel {
  label: string;
  count: number;
  percentage: number;
}

export interface CompletionWidgetConfig extends WidgetDataConfig {
  queues: string[];
}

interface CompletionWidgetProps {
  id: string;
  config: CompletionWidgetConfig;
  responseData: RawSurveyResponse[];
  editing: boolean;
  onEditWidgetClicked: any;
}

function CompletionWidget(props: CompletionWidgetProps) {
  const { t } = useTranslation();

  const [columnData, setColumnData] = useState<ColumnModel[]>([]);

  function OnEditWidgetClicked() {
    props.onEditWidgetClicked(props.id);
  }

  React.useEffect(() => {
    if (!props.responseData || props.responseData.length === 0) {
      setColumnData([]);
      return;
    }

    let newColumnData: ColumnModel[] = [];
    let collatedData: { [key: string]: { count: number } } = {};

    // Collate values first
    for (const res of props.responseData) {
      if (!(props.config.queues.length === 0 || props.config.queues.find((v) => v === res.QueueARN))) continue;
      if (res.QuestionNumber === -1) continue;

      if (collatedData[res.QuestionNumber]) collatedData[res.QuestionNumber].count++;
      else collatedData[res.QuestionNumber] = { count: 1 };
    }

    if (!collatedData[0]) {
      setColumnData([]);
      return;
    }

    const max = collatedData[0].count;

    // Then collapse into array:
    for (const q of Object.keys(collatedData)) {
      newColumnData.push({
        label: q === '0' ? 'Offered' : `Question ${q}`,
        count: collatedData[q].count,
        percentage: Math.round((collatedData[q].count * 10000) / max) / 100,
      });
    }

    setColumnData(newColumnData);

    debuglog('Completion rate:', collatedData, newColumnData);
  }, [props.responseData, props.config.queues]);

  const formatPercentage = React.useCallback((data: any) => {
    return `${data}%`;
  }, []);

  const formatLabel = React.useCallback((data: any) => {
    return data.count;
  }, []);

  const config: ColumnConfig = {
    data: columnData,
    xField: 'label',
    yField: 'percentage',
    xAxis: {
      label: {
        style: {
          fontSize: 16,
        },
      },
    },
    label: {
      rotate: 0,
      position: 'bottom',
      style: {
        fill: '#ffffff',
        opacity: 1,
        fontSize: 20,
      },
      formatter: formatLabel,
    },
    meta: {
      percentage: {
        alias: 'Response Rate',
        formatter: formatPercentage,
      },
    },
  };

  return (
    <Card
      style={{ height: '100%', cursor: props.editing ? 'move' : 'default' }}
      bodyStyle={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      <Text style={{ textAlign: 'center' }}>{props.config.label}</Text>
      {columnData.length > 0 ? (
        <Column {...config} style={{ width: '100%', height: '100%' }} />
      ) : (
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}

      <WidgetButtons editing={props.editing} buttons={[{ icon: <SettingOutlined />, tooltip: t('widgets.common.editwidget'), onClick: OnEditWidgetClicked }]} />
    </Card>
  );
}

export default CompletionWidget;
