import { Axios } from 'axios';
import debuglog from '../debugLog'; 
import { RawSurveyResponse } from './APITypes';

const gatewayUrl = process.env.REACT_APP_ENV === 'development' ? 'https://rwygv6k3jf.execute-api.ap-southeast-2.amazonaws.com/' : 'https://k6v4zqlokg.execute-api.ap-southeast-2.amazonaws.com/';

export async function GetSurveyResponses(axiosInstance: Axios, connectInstanceARN: string, start?: number, end?: number): Promise<RawSurveyResponse[]> {
  debuglog('GetSurveyResponses Start:', connectInstanceARN, start, end);

  const response = await axiosInstance.post(gatewayUrl, {
    action: 'getSurveyResponses',
    data: {
      start: start,
      end: end,
    },
    ConnectInstanceARN: connectInstanceARN,
  });

  debuglog('GetSurveyResponses:', response);
  return response.data.data.items;
}
