import React from 'react';

import { Typography } from 'antd';

const { Title, Text } = Typography;

function TopHeader(props: { user: string }) {
  return (
    <div
      id="header"
      style={{
        position: 'sticky',
        top: 0,
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 16px',
        background: '#001529',
        zIndex: 15,
      }}
    >
      <img src="favicon.png" alt="Rise CX Logo Splash" style={{ height: '24px' }} />
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Title
          id="header-title"
          level={4}
          style={{
            margin: '0',
            paddingLeft: '8px',
            color: '#FFF',
          }}
        >
          RiseXperience Voice of Customer
        </Title>
        <Text
          style={{
            margin: '0',
            color: '#FFF',
          }}
        >
          {props.user} {process.env.REACT_APP_ENV === 'development' && '🛠️'}
        </Text>
      </div>
    </div>
  );
}

export default TopHeader;
