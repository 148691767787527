import 'amazon-connect-streams';
import debuglog from './debugLog';

const containerdiv = document.getElementById('ccp-container-div') as HTMLDivElement | null;

export async function getAgentConfig(instanceURL: string, ssoURL: string): Promise<any> {
  if (!containerdiv) {
    debuglog('No CCP Container div! Aborting.');
    return;
  }

  debuglog('Amazon Connect Streams Initialisation - START');

  // Uninitialise CCP in case it is already initialised
  connect.core.terminate();

  // Get the connect instance information, via the logged in agent
  var agentConfig = await initConnect(containerdiv, instanceURL, ssoURL).catch((error) => {
    console.error('Connect Login Error', error);
  });
  debuglog('ARN', agentConfig);
  debuglog('Steams initialisation finished.  Terminating');
  connect.core.terminate();

  const iframe = containerdiv.firstElementChild; // assumes there's nothing else in the container
  if (iframe) containerdiv.removeChild(iframe);

  return agentConfig;
}

// initialize the streams api for connect instance information
export async function initConnect(container: HTMLDivElement, instanceURL: string, ssoURL: string) {
  try {
    // initialize the ccp
    return new Promise(async (resolve, reject) => {
      if (!instanceURL) {
        console.error('Instance URL error: ', instanceURL);
        reject(instanceURL);
      } else {
        debuglog('initCCP connect instance', instanceURL);

        connect.core.initCCP(container, {
          ccpUrl: instanceURL + 'connect/ccp-v2/', // REQUIRED TODO check for slashes on input
          loginPopup: true, // optional, defaults to `true`
          loginPopupAutoClose: true, // optional, defaults to `false`
          loginUrl: !ssoURL ? undefined : ssoURL,
          loginOptions: {
            // optional, if provided opens login in new window
            autoClose: true, // optional, defaults to `false`
            height: 800, // optional, defaults to 578
            width: 600, // optional, defaults to 433
            top: 0, // optional, defaults to 0
            left: 0, // optional, defaults to 0
          },
          softphone: {
            allowFramedSoftphone: true, // optional, defaults to false
          },
        });

        connect.agent(async (agent) => {
          if (agent) {
            debuglog('Agent found:', agent);
            resolve(agent.getConfiguration());
          } else {
            debuglog('No agent!');
            reject('No agent!');
          }
        });
      }
    });
  } catch (e) {
    debuglog(e);
  }
}
