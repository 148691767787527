import { SoundOutlined } from '@ant-design/icons';
import { Button, Col, Form, InputNumber, Row, Select, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AgentDataModel, QuestionDataModel, QueueDataModel } from '../../../scripts/API/APITypes';
import CompletionWidgetSettings from '../completion/CompletionWidgetSettings';
import CountWidgetSettings from '../count/CountWidgetSettings';
import { LeaderboardWidgetConfig } from '../leaderboard/LeaderboardWidget';
import LeaderboardWidgetSettings, { LeaderboardType } from '../leaderboard/LeaderboardWidgetSettings';
import ScoreWidgetSettings from '../score/ScoreWidgetSettings';
import { WidgetConfig, WidgetType } from './WidgetTypes';

const { Option } = Select;

interface WidgetSettingsFormProps {
  widgetType: WidgetType | null;
  queueList: { [key: string]: QueueDataModel };
  questionList: { [key: string]: QuestionDataModel };
  agentList: { [key: string]: AgentDataModel };
  currentWidget: WidgetConfig | null;
  availableAlarms: string[];
  openAlarmModal: () => void;
}

function WidgetSettingsForm(props: WidgetSettingsFormProps) {
  const { t } = useTranslation();

  const [selectedQueues, setSelectedQueues] = useState<string[]>([]);
  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);
  const [selectedAgents, setSelectedAgents] = useState<string[]>([]);

  const [leaderboardType, setLeaderboardType] = useState<LeaderboardType>(LeaderboardType.Average);

  useEffect(() => {
    if (props.widgetType !== WidgetType.Leaderboard) return;
    const cfg = props.currentWidget?.config as LeaderboardWidgetConfig;
    if (!cfg) return;

    setLeaderboardType(cfg.type);
  }, [props.currentWidget, props.widgetType]);

  return (
    <div>
      {props.widgetType === WidgetType.Count &&
        CountWidgetSettings(
          t,
          props.queueList,
          selectedQueues,
          setSelectedQueues,
          props.questionList,
          selectedQuestions,
          setSelectedQuestions,
          props.agentList,
          selectedAgents,
          setSelectedAgents,
          props.availableAlarms,
          props.openAlarmModal,
        )}

      {props.widgetType === WidgetType.Score &&
        ScoreWidgetSettings(
          t,
          props.queueList,
          selectedQueues,
          setSelectedQueues,
          props.questionList,
          selectedQuestions,
          setSelectedQuestions,
          props.agentList,
          selectedAgents,
          setSelectedAgents,
          props.availableAlarms,
          props.openAlarmModal,
        )}

      {props.widgetType === WidgetType.Leaderboard &&
        LeaderboardWidgetSettings(t, leaderboardType, setLeaderboardType, props.queueList, selectedQueues, setSelectedQueues, props.questionList, selectedQuestions, setSelectedQuestions)}

      {props.widgetType === WidgetType.Completion && CompletionWidgetSettings(t, props.queueList, setSelectedQueues, selectedQueues)}
    </div>
  );
}

export function QueueSelectionComponent(t: any, queueList: { [key: string]: QueueDataModel }, selectedQueues: string[], setSelectedQueues: (value: string[]) => void) {
  return (
    <div>
      <Form.Item label="Filter by Queues:" name="queues">
        <Select
          placeholder={t('widgetsettingsform.queueselection.placeholder')}
          mode="multiple"
          allowClear
          value={selectedQueues}
          onChange={setSelectedQueues}
          filterOption={(input, option) => {
            if (option) return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            else return true;
          }}
        >
          {Object.keys(queueList).map((q) => (
            <Select.Option key={queueList[q].arn} value={queueList[q].arn}>
              {queueList[q].name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
}

export function QuestionSelectionComponent(t: any, questionList: { [key: string]: QuestionDataModel }, selectedQuestions: string[], setSelectedQuestions: (value: string[]) => void) {
  return (
    <div>
      <Form.Item label="Filter by Questions:" name="questions">
        <Select
          placeholder="Select Questions"
          mode="multiple"
          allowClear
          value={selectedQuestions}
          onChange={setSelectedQuestions}
          filterOption={(input, option) => {
            if (option) return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            else return true;
          }}
        >
          {Object.keys(questionList).map((q) => (
            <Select.Option key={questionList[q].number} value={questionList[q].number}>
              {questionList[q].number} - {questionList[q].question}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
}

export function AgentSelectionComponent(t: any, agentList: { [key: string]: AgentDataModel }, selectedAgents: string[], setSelectedAgents: (value: string[]) => void) {
  return (
    <div>
      <Form.Item label="Filter by Agents:" name="agents">
        <Select
          placeholder="Select Agents"
          mode="multiple"
          allowClear
          value={selectedAgents}
          onChange={setSelectedAgents}
          filterOption={(input, option) => {
            if (option) return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            else return true;
          }}
        >
          {Object.keys(agentList).map((q) => (
            <Select.Option key={agentList[q].id} value={agentList[q].id}>
              {agentList[q].firstName} {agentList[q].lastName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
}

export function ThresholdComponent(props: { t: any; availableAlarms?: string[]; openAlarmModal?: () => void }) {
  return (
    <div>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item label={props.t('widgetsettingsform.metricwidget.metricamber.label')} name="metricamber" tooltip={props.t('widgetsettingsform.metricwidget.metricamber.tooltip')}>
            <InputNumber />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label={props.t('widgetsettingsform.metricwidget.metricred.label')} name="metricred" tooltip={props.t('widgetsettingsform.metricwidget.metricred.tooltip')}>
            <InputNumber />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label={props.t('widgetsettingsform.metricwidget.metricblink.label')} name="metricblink" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label={'Show  as green'} name="showasgreen" valuePropName="checked" tooltip="Change widget colour to green when no thresholds have been crossed">
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      {props.availableAlarms && (
        <>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item label={props.t('widgetsettingsform.metricwidget.metricambersound.label')} name="metricambersound">
                <Select>
                  <Option value="">None</Option>
                  {props.availableAlarms.map((n, i) => {
                    return (
                      <Option key={i} value={n}>
                        {n}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label={props.t('widgetsettingsform.metricwidget.metricredsound.label')} name="metricredsound">
                <Select>
                  <Option value="">None</Option>
                  {props.availableAlarms.map((n, i) => {
                    return (
                      <Option key={i} value={n}>
                        {n}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button onClick={props.openAlarmModal} icon={<SoundOutlined />}>
                  {props.t('header.managealarms')}
                </Button>
              </div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default WidgetSettingsForm;
