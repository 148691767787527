import { Collapse, Form, Input } from 'antd';
import { QueueDataModel } from '../../../scripts/API/APITypes';
import { QueueSelectionComponent } from '../common/WidgetSettingsForm';

const { Panel } = Collapse;

export default function GaugeWidgetSettings(t: any, queueList: { [key: string]: QueueDataModel }, setSelectedQueues: (value: string[]) => void, selectedQueues: string[]) {
  return (
    <div>
      <Form.Item label="Label" name="label">
        <Input />
      </Form.Item>

      <Collapse>
        <Panel header="Filters" key="1">
          {QueueSelectionComponent(t, queueList, selectedQueues, setSelectedQueues)}
        </Panel>
      </Collapse>
    </div>
  );
}
