import * as React from 'react';
import { useCallback, useState } from 'react';

import { SettingOutlined, TrophyFilled } from '@ant-design/icons';
import { Card, Divider, List, Progress, Typography } from 'antd';
import { RawSurveyResponse } from '../../../scripts/API/APITypes';
import debuglog from '../../../scripts/debugLog';
import WidgetButtons from '../common/WidgetButtons';
import { WidgetDataConfig } from '../common/WidgetTypes';
import { LeaderboardType } from './LeaderboardWidgetSettings';

const { Text } = Typography;

export interface LeaderboardWidgetConfig extends WidgetDataConfig {
  type: LeaderboardType;
  queues: string[];
  questions: number[];
  minimumScore?: number;
  maximumScore?: number;
  minimumResponses: number;
}

interface LeaderboardModel {
  agentName: string;
  score: number;
}

interface LeaderboardWidgetProps {
  id: string;
  config: LeaderboardWidgetConfig;
  responseData: RawSurveyResponse[];
  editing: boolean;
  onEditWidgetClicked: any;
}

function LeaderboardWidget(props: LeaderboardWidgetProps) {
  const [leaderboardSource, setLeaderboardSource] = useState<LeaderboardModel[]>([]);

  function OnEditWidgetClicked() {
    props.onEditWidgetClicked(props.id);
  }

  const checkResponseFiltered = useCallback(
    (res: RawSurveyResponse): boolean => {
      if (!(props.config.queues.length === 0 || props.config.queues.find((v) => v === res.QueueARN))) return true;
      if (!(props.config.questions.length === 0 || props.config.questions.find((v) => v === res.QuestionNumber))) return true;
      return false;
    },
    [props.config],
  );

  const aggregateAverageScores = useCallback(() => {
    const tally: { [key: string]: { name: string; count: number; sum: number } } = {};

    for (const res of props.responseData) {
      const parsed = parseInt(res.Transcript);
      if (isNaN(parsed)) continue;
      if (checkResponseFiltered(res)) continue;

      if (tally[res.AgentId]) {
        tally[res.AgentId].count++;
        tally[res.AgentId].sum += parsed;
      } else
        tally[res.AgentId] = {
          name: `${res.AgentFirstName} ${res.AgentLastName}`,
          count: 1,
          sum: parsed,
        };
    }

    debuglog(`Calculated tally:`, tally, 'with', props.config);

    const newLeaderboardSource: LeaderboardModel[] = [];
    for (const key of Object.keys(tally)) {
      const agent = tally[key];
      if (agent.count < props.config.minimumResponses) continue;

      newLeaderboardSource.push({
        agentName: agent.name,
        score: Math.floor((agent.sum * 100) / agent.count) / 100,
      });
    }

    newLeaderboardSource.sort((a, b) => b.score - a.score);

    setLeaderboardSource(newLeaderboardSource);
  }, [checkResponseFiltered, props.config, props.responseData]);

  const aggregateScoreCounts = useCallback(() => {
    const tally: { [key: string]: { name: string; count: number } } = {};

    const min = props.config.minimumScore ?? 0;
    const max = props.config.maximumScore ?? 100;

    for (const res of props.responseData) {
      const parsed = parseInt(res.Transcript);
      if (isNaN(parsed)) continue;
      if (checkResponseFiltered(res)) continue;
      if (parsed < min || parsed > max) continue;

      if (tally[res.AgentId]) {
        tally[res.AgentId].count++;
      } else
        tally[res.AgentId] = {
          name: `${res.AgentFirstName} ${res.AgentLastName}`,
          count: 1,
        };
    }

    debuglog(`Calculated tally:`, tally, 'with', props.config);

    const newLeaderboardSource: LeaderboardModel[] = [];
    for (const key of Object.keys(tally)) {
      const agent = tally[key];
      if (agent.count < props.config.minimumResponses) continue;

      newLeaderboardSource.push({
        agentName: agent.name,
        score: agent.count,
      });
    }

    newLeaderboardSource.sort((a, b) => b.score - a.score);

    setLeaderboardSource(newLeaderboardSource);
  }, [checkResponseFiltered, props.config, props.responseData]);

  React.useEffect(() => {
    if (!props.responseData || Object.keys(props.responseData).length === 0) return;

    switch (props.config.type) {
      case LeaderboardType.Average:
        aggregateAverageScores();
        return;

      case LeaderboardType.Count:
        aggregateScoreCounts();
        return;
    }

    //setValue(total.toString());
  }, [props.responseData, props.config.type, aggregateAverageScores, aggregateScoreCounts]);

  function renderListItem(item: LeaderboardModel, i: number) {
    let icon: JSX.Element | null = null;

    if (i <= 2) {
      switch (i) {
        case 0:
          icon = <TrophyFilled style={{ fontSize: '20px', color: 'gold' }} />;
          break;
        case 1:
          icon = <TrophyFilled style={{ fontSize: '20px', color: 'silver' }} />;
          break;
        case 2:
          icon = <TrophyFilled style={{ fontSize: '20px', color: 'chocolate' }} />;
          break;
      }
    } else {
      icon = <div>#{i + 1}</div>;
    }

    const percent = (item.score / leaderboardSource[0].score) * 100;

    return (
      <List.Item>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
          <div style={{ width: '25%', display: 'flex', justifyContent: 'space-between' }}>
            {icon}
            <Text style={{ textAlign: 'center' }}>{item.agentName}</Text>
            <Divider type="vertical" />
          </div>
          <div style={{ width: '75%', display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
            <Progress
              strokeColor={{
                '60%': '#6495ED',
                '100%': '#9FE2BF',
              }}
              percent={percent}
              showInfo={false}
            />
            <Text style={{ width: '48px' }}>{item.score}</Text>
          </div>
        </div>
      </List.Item>
    );
  }

  function getLeaderboardTypeLabel(): string {
    switch (props.config.type) {
      case LeaderboardType.Average:
        return 'Ranked by Average Score';
      case LeaderboardType.Count:
        return `Ranked by Score Count (Between ${props.config.minimumScore} and ${props.config.maximumScore})`;
      default:
        return '';
    }
  }

  return (
    <div style={{ height: '100%', cursor: props.editing ? 'move' : 'default' }}>
      <Card
        style={{ height: '100%' }}
        bodyStyle={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <Text>{props.config.label}</Text>
        <List style={{ width: '100%' }} itemLayout="horizontal" dataSource={leaderboardSource} header={<Text type="secondary">{getLeaderboardTypeLabel()}</Text>} renderItem={renderListItem} />
        <WidgetButtons editing={props.editing} buttons={[{ icon: <SettingOutlined />, tooltip: 'Edit Widget', onClick: OnEditWidgetClicked }]} />
      </Card>
    </div>
  );
}

export default LeaderboardWidget;
