import { CalendarOutlined } from '@ant-design/icons';
import { Button, DatePicker, Popover, Space, Switch, Typography } from 'antd';
import { DateTime } from 'luxon';
import moment from 'moment';
import { useState } from 'react';
import debuglog from '../scripts/debugLog';

const { Text } = Typography;
const { RangePicker } = DatePicker;

interface DateSelectorProps {
  onDateChanged: () => void;
  daily: boolean;
  setDaily: (v: boolean) => void;
  timeRange: [any, any];
  setTimeRange: (v: [any, any]) => void;
}

export default function DateSelector(props: DateSelectorProps) {
  const [visible, setVisible] = useState<boolean>(false);

  function onMidnightToNowChanged(checked: boolean) {
    props.setTimeRange([undefined, undefined]);
    props.setDaily(checked);
  }

  function onDateRangeChanged(dates: any, dateStrings: [string, string]) {
    debuglog('DATES', dates, dateStrings);
    props.setTimeRange(dates);
  }

  function checkDates() {
    debuglog('check dates', props.timeRange);
    if (!props.timeRange || !props.timeRange[0] || !props.timeRange[1]) {
      props.setTimeRange([undefined, undefined]);
      props.setDaily(true);
    }
  }

  function getReadableTimeZone() {
    return `${DateTime.now().zoneName} (GMT${DateTime.now().toFormat('ZZ')})`;
  }

  function getDateDisplayText(): string {
    if (props.daily) return 'Midnight to Now';
    if (!props.timeRange || !props.timeRange[0] || !props.timeRange[1]) return '';
    return `${props.timeRange[0].format('DD/MM/YYYY HH:mm')} - ${props.timeRange[1].format('DD/MM/YYYY HH:mm')}`;
  }

  return (
    <Space direction="horizontal" align="center">
      <Popover
        placement="bottomLeft"
        title="Apply Time Range"
        visible={visible}
        trigger="click"
        onVisibleChange={async (visible: boolean) => {
          setVisible(visible);
          if (!visible) {
            checkDates();
            await props.onDateChanged();
          }
        }}
        content={
          <Space direction="vertical">
            <Text>Time Zone: {getReadableTimeZone()}</Text>

            <Space direction="horizontal" align="center">
              <Text>Midnight to Now: </Text>
              <Switch checked={props.daily} onChange={onMidnightToNowChanged} />
            </Space>

            <Space direction="vertical" align="center">
              <RangePicker
                ranges={{
                  'Week to Date': [moment().startOf('week'), moment()],
                  'Month to Date': [moment().startOf('month'), moment()],
                  'Last Month': [moment().date(0).startOf('month'), moment().date(0).endOf('month')],
                }}
                disabled={props.daily}
                value={props.timeRange}
                showTime={{ format: 'HH:mm' }}
                format="YYYY/MM/DD HH:mm"
                onChange={onDateRangeChanged}
              />
            </Space>
            <Space style={{ width: '100%' }} direction="vertical" align="end">
              <Space direction="horizontal">
                <Button
                  type="primary"
                  onClick={async () => {
                    setVisible(false);
                    checkDates();
                    await props.onDateChanged();
                  }}
                >
                  OK
                </Button>
              </Space>
            </Space>
          </Space>
        }
      >
        <Button icon={<CalendarOutlined />}> {getDateDisplayText()}</Button>
      </Popover>
    </Space>
  );
}
