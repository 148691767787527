import { WarningOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Popconfirm, Row, Space } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import WidgetSettingsForm from '../components/widgets/common/WidgetSettingsForm';
import { WidgetConfig, WidgetType } from '../components/widgets/common/WidgetTypes';
import { CompletionWidgetConfig } from '../components/widgets/completion/CompletionWidget';
import { CountWidgetConfig, CountType } from '../components/widgets/count/CountWidget';
import { LeaderboardWidgetConfig } from '../components/widgets/leaderboard/LeaderboardWidget';
import { ScoreWidgetConfig } from '../components/widgets/score/ScoreWidget';
import { AgentDataModel, QuestionDataModel, QueueDataModel } from '../scripts/API/APITypes';
import debuglog from '../scripts/debugLog';

interface EditWidgetModalProps {
  currentWidget: WidgetConfig | null;
  isVisible: boolean;
  handleOk: any;
  handleCancel: any;
  handleDelete: any;
  queueList: { [key: string]: QueueDataModel };
  questionList: { [key: string]: QuestionDataModel };
  agentList: { [key: string]: AgentDataModel };
  availableAlarms: string[];
  openAlarmModal: () => void;
  availableAgents: string[];
}

function EditWidgetModal(props: EditWidgetModalProps) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  async function handleOk() {
    if (!props.currentWidget) return;

    try {
      const newConfig: WidgetConfig = props.currentWidget;
      const values = await form.validateFields();

      debuglog('Success', values);

      if (props.currentWidget) {
        switch (props.currentWidget.type) {
          case WidgetType.Count:
            newConfig.config = {
              countType: values.counttype,
              queues: values.queues || [],
              questions: values.questions || [],
              agents: values.agents || [],
              label: values.label,
              amberThreshold: values.metricamber,
              redThreshold: values.metricred,
              showAsGreen: values.showasgreen,
              amberSound: values.metricambersound,
              redSound: values.metricredsound,
              blink: values.metricblink,
            } as CountWidgetConfig;
            break;

          case WidgetType.Score:
            newConfig.config = {
              queues: values.queues || [],
              questions: values.questions || [],
              agents: values.agents || [],
              label: values.label,
              amberThreshold: values.metricamber,
              redThreshold: values.metricred,
              showAsGreen: values.showasgreen,
              amberSound: values.metricambersound,
              redSound: values.metricredsound,
              blink: values.metricblink,
            } as ScoreWidgetConfig;
            break;

          case WidgetType.Leaderboard:
            newConfig.config = {
              label: values.label,
              type: values.leaderboardtype,
              queues: values.queues || [],
              questions: values.questions || [],
              minimumScore: values.minimumscore || 0,
              maximumScore: values.maximumscore || 0,
              minimumResponses: values.minimumresponses,
            } as LeaderboardWidgetConfig;
            break;

          case WidgetType.Completion:
            newConfig.config = {
              label: values.label,
              queues: values.queues || [],
              amberThreshold: values.metricamber,
              redThreshold: values.metricred,
              amberSound: values.metricambersound,
              redSound: values.metricredsound,
              blink: values.metricblink,
            } as CompletionWidgetConfig;
            break;
        }
      }

      props.handleOk(newConfig);
    } catch (errorInfo) {
      debuglog('Failed:', errorInfo);
    }
  }

  // Build initial form values on modal open:
  React.useEffect(() => {
    if (!props.currentWidget) return;

    switch (props.currentWidget.type) {
      case WidgetType.Count:
        const countConfig = props.currentWidget.config as CountWidgetConfig;
        debuglog('count widget', countConfig);
        form.setFieldsValue({
          counttype: countConfig.countType || CountType.Completed,
          queues: countConfig.queues || [],
          questions: countConfig.questions || [],
          agents: countConfig.agents || [],
          label: countConfig.label || '',
          metricamber: countConfig.amberThreshold || 0,
          metricred: countConfig.redThreshold || 0,
          metricambersound: countConfig.amberSound || '',
          metricredsound: countConfig.redSound || '',
          metricblink: countConfig.blink || false,
          showasgreen: countConfig.showAsGreen || false,
        });
        break;

      case WidgetType.Score:
        const scoreConfig = props.currentWidget.config as ScoreWidgetConfig;
        form.setFieldsValue({
          queues: scoreConfig.queues || [],
          questions: scoreConfig.questions || [],
          agents: scoreConfig.agents || [],
          label: scoreConfig.label || '',
          metricamber: scoreConfig.amberThreshold || 0,
          metricred: scoreConfig.redThreshold || 0,
          metricambersound: scoreConfig.amberSound || '',
          metricredsound: scoreConfig.redSound || '',
          metricblink: scoreConfig.blink || false,
          showasgreen: scoreConfig.showAsGreen || false,
        });
        break;

      case WidgetType.Leaderboard:
        const lbConfig = props.currentWidget.config as LeaderboardWidgetConfig;
        form.setFieldsValue({
          label: lbConfig.label || '',
          leaderboardtype: lbConfig.type,
          queues: lbConfig.queues || [],
          questions: lbConfig.questions || [],
          minimumscore: lbConfig.minimumScore || 0,
          maximumscore: lbConfig.maximumScore || 0,
          minimumresponses: lbConfig.minimumResponses || 0,
        });
        break;

      case WidgetType.Completion:
        const completionConfig = props.currentWidget.config as CompletionWidgetConfig;
        form.setFieldsValue({
          queues: completionConfig.queues || [],
          label: completionConfig.label || '',
        });
        break;
    }
  }, [form, props.currentWidget]);

  return (
    <Modal
      title={t('editwidgetmodal.title', { widgetid: props.currentWidget?.widgetid })}
      visible={props.isVisible}
      onOk={handleOk}
      onCancel={props.handleCancel}
      destroyOnClose={true}
      forceRender={true}
      footer={[
        <Row key="editwidgetfooter" justify="space-between">
          <Popconfirm
            key="deleteconfirm"
            title={t('editwidgetmodal.footer.delete.confirm')}
            onConfirm={props.handleDelete}
            okText={t('common.yes')}
            cancelText={t('common.cancel')}
            placement="topLeft"
            icon={<WarningOutlined style={{ color: 'red' }} />}
          >
            <Button key="delete" type="primary" danger>
              {t('editwidgetmodal.footer.delete.label')}
            </Button>
          </Popconfirm>
          <Space>
            <Button key="back" onClick={props.handleCancel}>
              {t('common.cancel')}
            </Button>
            <Button key="submit" type="primary" onClick={handleOk}>
              {t('common.save')}
            </Button>
          </Space>
        </Row>,
      ]}
    >
      <Form form={form} layout="vertical" name="editWidgetConfig">
        {WidgetSettingsForm({
          widgetType: props.currentWidget ? props.currentWidget.type : null,
          queueList: props.queueList,
          questionList: props.questionList,
          agentList: props.agentList,
          currentWidget: props.currentWidget ? props.currentWidget : null,
          availableAlarms: props.availableAlarms,
          openAlarmModal: props.openAlarmModal,
        })}
      </Form>
    </Modal>
  );
}

export default EditWidgetModal;
