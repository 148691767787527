import { CaretDownOutlined, CaretUpOutlined, DownSquareOutlined, MinusSquareOutlined, PlusSquareOutlined, RightSquareOutlined } from '@ant-design/icons';
import { Empty, Input, Pagination, Space } from 'antd';
import React, { useState } from 'react';
import { Row, useAsyncDebounce, useExpanded, useGlobalFilter, useGroupBy, usePagination, useSortBy, useTable } from 'react-table';
import '../styles/DataTable.css';

export interface DataTableProps<T extends object> {
  data: T[];
  columns: any;
  fetchingData: boolean;
}

function Pager(props: { pageIndex: number; pageCount: number; pageSize: number; setPageSize: (size: number) => void; gotoPage: (page: number) => void }) {
  return (
    <Pagination
      showSizeChanger
      onShowSizeChange={(current: number, size: number) => {
        props.setPageSize(size);
      }}
      style={{ display: 'flex', margin: '16px 0px', justifyContent: 'end' }}
      current={props.pageIndex + 1}
      total={props.pageCount * props.pageSize}
      pageSize={props.pageSize}
      onChange={(page: number) => {
        props.gotoPage(page - 1);
      }}
    />
  );
}

function DataTable<T extends object>(props: DataTableProps<T>) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    // Pagination
    page,
    pageCount,
    setPageSize,
    gotoPage,

    // Search / Filter
    preGlobalFilteredRows,
    setGlobalFilter,

    state: { pageSize, pageIndex, globalFilter },
  } = useTable(
    { columns: props.columns, data: props.data, autoResetGroupBy: false, autoResetExpanded: false, autoResetSortBy: false },
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
  );

  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    // apply the table props
    <div>
      <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Space>
          <Input
            style={{ width: '320px' }}
            value={value || ''}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            placeholder={`Search ${preGlobalFilteredRows.length} records...`}
          />
        </Space>

        <Pager pageIndex={pageIndex} pageCount={pageCount} pageSize={pageSize} setPageSize={setPageSize} gotoPage={gotoPage} />
      </Space>

      <table className="data-table" {...getTableProps()}>
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.canGroupBy ? (
                        // If the column can be grouped, let's add a toggle
                        <span {...column.getGroupByToggleProps()}>
                          {column.isGrouped ? <MinusSquareOutlined style={{ marginRight: '4px' }} /> : <PlusSquareOutlined style={{ marginRight: '4px' }} />}
                        </span>
                      ) : null}
                      {
                        // Render the header
                        column.render('Header')
                      }
                      {
                        // Add a sort direction indicator
                        <span>{column.isSorted ? column.isSortedDesc ? <CaretUpOutlined style={{ marginLeft: '4px' }} /> : <CaretDownOutlined style={{ marginLeft: '4px' }} /> : ''}</span>
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>

        {/* Apply the table body props */}

        {!props.data || props.data.length === 0 ? (
          <tbody {...getTableBodyProps()}>
            <tr>
              <td colSpan={props.columns.length}>
                <div
                  style={{
                    padding: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              page.map((row: Row<T>) => {
                // Prepare the row for display
                prepareRow(row);
                const rowProps = row.getRowProps();
                return (
                  <React.Fragment key={rowProps.key}>
                    <tr {...rowProps}>
                      {
                        // Loop over the rows cells
                        row.cells.map((cell: any) => {
                          // Apply the cell props
                          return (
                            <td {...cell.getCellProps()} {...(row.groupByID && row.getToggleRowExpandedProps())}>
                              {cell.isGrouped ? (
                                // If it's a grouped cell, add an expander and row count
                                <>
                                  <span>{row.isExpanded ? <DownSquareOutlined /> : <RightSquareOutlined />}</span> {cell.render('Cell')} ({row.subRows.length})
                                </>
                              ) : cell.isAggregated ? (
                                // If the cell is aggregated, use the Aggregated
                                // renderer for cell
                                cell.render('Aggregated')
                              ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                                // Otherwise, just render the regular cell
                                cell.render('Cell')
                              )}
                            </td>
                          );
                        })
                      }
                    </tr>
                  </React.Fragment>
                );
              })
            }
          </tbody>
        )}
      </table>

      <Pager pageIndex={pageIndex} pageCount={pageCount} pageSize={pageSize} setPageSize={setPageSize} gotoPage={gotoPage} />
    </div>
  );
}

export default DataTable;
