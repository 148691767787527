import * as React from 'react';

import { Button, Checkbox, Form, Input, Typography } from 'antd';

import debuglog from '../scripts/debugLog';

const { Paragraph } = Typography;
interface InitConnectModalProps {
  initConnect: (instanceURL: string, ssoURL: string) => void;
  loading: boolean;
}

function InitConnectModal(props: InitConnectModalProps) {
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (global.localStorage) {
      try {
        const rememberedvalues = global.localStorage.getItem('rxpcs-connection');
        if (rememberedvalues) form.setFieldsValue(JSON.parse(rememberedvalues));
      } catch {}
    }
  }, [form]);

  async function submitForm(values: any) {
    var instanceURL = values['instanceURL'];
    var ssoURL = values['ssoURL'];
    var remember = values['remember'];

    debuglog(values);

    if (global.localStorage) {
      global.localStorage.setItem('rxpcs-rememberconnection', JSON.stringify(remember));

      if (remember) {
        global.localStorage.setItem('rxpcs-connection', JSON.stringify(values));
      } else {
        global.localStorage.removeItem('rxpcs-connection');
      }
    }

    await props.initConnect(instanceURL, ssoURL);
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: '640px',
          padding: '48px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '12px',
        }}
      >
        <Form form={form} layout="vertical" name="initConnectConfig" onFinish={submitForm} requiredMark={false} initialValues={{ remember: true }}>
          <Paragraph>In order to authenticate, please login to your Amazon Connect instance below:</Paragraph>

          <Form.Item
            label="Enter the full Connect URL, eg. https://YOURINSTANCE.awsapps.com/ or https://YOURINSTANCE.my.connect.aws/"
            name="instanceURL"
            rules={[
              () => ({
                validator(_, value: string) {
                  if (value.match(/(http|https):\/\/(.*)(\.my\.connect\.aws|\.awsapps\.com)/g)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(new Error('This is not a valid URL'));
                  }
                },
              }),
            ]}
          >
            <Input placeholder="Please enter your Amazon Connect instance URL" />
          </Form.Item>

          <Form.Item label="If you use SSO, enter the SSO redirect URL (ask your admin if you do not know what it is)" name="ssoURL">
            <Input placeholder="SSO URL (Optional)" />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember this Amazon Connect Instance</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={props.loading}>
              Log into Amazon Connect
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default InitConnectModal;
