import * as React from 'react';
import { useCallback, useState } from 'react';
import useResizeObserver from 'use-resize-observer';

import { BellOutlined, SettingOutlined } from '@ant-design/icons';
import { Card, Statistic, Typography } from 'antd';
import tinycolor from 'tinycolor2';
import { RawSurveyResponse } from '../../../scripts/API/APITypes';
import debuglog from '../../../scripts/debugLog';
import WidgetButtons from '../common/WidgetButtons';
import { WidgetDataConfig } from '../common/WidgetTypes';

const { Text } = Typography;

export enum CountType {
  Completed,
  Offered,
  Partial,
}

export interface CountWidgetConfig extends WidgetDataConfig {
  countType: CountType;
  queues: string[];
  questions: number[];
  agents: string[];
  amberThreshold: number;
  redThreshold: number;
  showAsGreen: boolean;
  amberSound: string;
  redSound: string;
  blink: boolean;
  sla: string;
}

interface CountWidgetProps {
  id: string;
  config: CountWidgetConfig;
  responseData: RawSurveyResponse[];
  editing: boolean;
  onEditWidgetClicked: any;
  amberColor: string;
  redColor: string;
  greenColor: string;
  queueList: { name: string; id: string }[];
  triggerAlarm: (id: string, alarmSound: string) => void;
  dismissAlarm: (id: string) => void;
}

function CountWidget(props: CountWidgetProps) {
  const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();

  const [labelColor, setLabelColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [blink, setBlink] = useState(false);

  const [value, setValue] = useState('-');

  const [alarming, setAlarming] = useState<boolean>(false);

  function OnEditWidgetClicked() {
    props.onEditWidgetClicked(props.id);
  }

  React.useEffect(() => {
    if (!value) return;

    const amber = props.config.amberThreshold || 0;
    const red = props.config.redThreshold || 0;
    const checkLess = red < amber;
    const val = parseInt(value);

    if ((red && !checkLess && val >= red) || (checkLess && val <= red)) {
      // Red triggered:
      setLabelColor(props.redColor);
      setBackgroundColor(tinycolor(props.redColor).setAlpha(0.2).toString());
      if (props.config.blink) setBlink(true);
      else setBlink(false);

      if (props.config.redSound !== '') {
        props.triggerAlarm(props.id, props.config.redSound);
        setAlarming(true);
      } else {
        props.dismissAlarm(props.id);
        setAlarming(false);
      }
    } else if ((amber && !checkLess && val >= amber) || (checkLess && val <= amber)) {
      // Amber triggered:
      setLabelColor(props.amberColor);
      setBackgroundColor(tinycolor(props.amberColor).setAlpha(0.2).toString());
      setBlink(false);

      setAlarming(false);
      if (props.config.amberSound !== '') {
        props.triggerAlarm(props.id, props.config.amberSound);
        setAlarming(true);
      } else {
        props.dismissAlarm(props.id);
        setAlarming(false);
      }
    } else {
      // No threshold:
      if (props.config.showAsGreen && value !== '-') {
        setLabelColor(props.greenColor);
        setBackgroundColor(tinycolor(props.greenColor).setAlpha(0.2).toString());
      } else {
        setLabelColor('');
        setBackgroundColor('');
      }
      setBlink(false);
      props.dismissAlarm(props.id);
      setAlarming(false);
    }
  }, [value, props]);

  const checkResponseFiltered = useCallback(
    (res: RawSurveyResponse): boolean => {
      if (!(props.config.queues.length === 0 || props.config.queues.find((v) => v === res.QueueARN))) return true;
      if (!(props.config.questions.length === 0 || props.config.questions.find((v) => v === res.QuestionNumber))) return true;
      if (!(props.config.agents.length === 0 || props.config.agents.find((v) => v === res.AgentId))) return true;
      return false;
    },
    [props.config],
  );

  React.useEffect(() => {
    if (!props.responseData || Object.keys(props.responseData).length === 0) return;

    let total: number = 0;

    switch (props.config.countType) {
      case CountType.Completed:
        for (const res of props.responseData) {
          if (checkResponseFiltered(res)) continue;

          if (res.QuestionNumber === -1) {
            total++;
          }
        }
        break;
      case CountType.Offered:
        for (const res of props.responseData) {
          if (checkResponseFiltered(res)) continue;

          if (res.QuestionNumber === 0) {
            total++;
          }
        }
        break;
      case CountType.Partial:
        let completed = 0;
        let offered = 0;
        for (const res of props.responseData) {
          if (checkResponseFiltered(res)) continue;

          if (res.QuestionNumber === -1) {
            completed++;
          } else if (res.QuestionNumber === 0) {
            offered++;
          }
        }
        total = offered - completed;
        break;
      default:
        break;
    }

    debuglog(`Calculated total:`, total, 'with', props.config);

    setValue(total.toString());
  }, [props.responseData, props.config, checkResponseFiltered]);

  function getCountTypeName(): string {
    switch (props.config.countType) {
      case CountType.Completed:
        return 'Surveys Completed';
      case CountType.Offered:
        return 'Surveys Offered';
      case CountType.Partial:
        return 'Surveys Partially Completed';
      default:
        return '';
    }
  }

  return (
    <div ref={ref} style={{ height: '100%', cursor: props.editing ? 'move' : 'default' }} className={`data-widget ${blink ? 'blink' : ''}`}>
      <Card
        bodyStyle={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: backgroundColor,
        }}
      >
        <Text>{props.config.label}</Text>
        <Statistic title={getCountTypeName()} value={value} style={{ textAlign: 'center' }} valueStyle={{ fontSize: Math.min(width, height) / 4, color: labelColor }} />
        <div hidden={!props.config.amberSound && !props.config.redSound}>
          <BellOutlined style={{ color: labelColor, position: 'absolute', top: '8px', left: '8px' }} className={alarming ? 'ring-icon' : undefined} />
        </div>

        <WidgetButtons editing={props.editing} buttons={[{ icon: <SettingOutlined />, tooltip: 'Edit Widget', onClick: OnEditWidgetClicked }]} />
      </Card>
    </div>
  );
}

export default CountWidget;
