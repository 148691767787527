import { Col, Collapse, Form, Input, InputNumber, Segmented } from 'antd';
import { QuestionDataModel, QueueDataModel } from '../../../scripts/API/APITypes';
import { QuestionSelectionComponent, QueueSelectionComponent } from '../common/WidgetSettingsForm';

const { Panel } = Collapse;

export enum LeaderboardType {
  Average,
  Count,
}

export default function LeaderboardWidgetSettings(
  t: any,
  type: LeaderboardType,
  setType: (t: LeaderboardType) => void,
  queueList: { [key: string]: QueueDataModel },
  selectedQueues: string[],
  setSelectedQueues: (value: string[]) => void,
  questionList: { [key: string]: QuestionDataModel },
  selectedQuestions: string[],
  setSelectedQuestions: (value: string[]) => void,
) {
  return (
    <div>
      <Form.Item label={'Label'} name="label">
        <Input />
      </Form.Item>

      <Form.Item label={'Leaderboard Type'} name="leaderboardtype" tooltip={'Score Average ranks agents by their overall average score. Score Count ranks agents by the number of scores received.'}>
        <Segmented
          onChange={(v) => {
            let parsed = +v;
            if (isNaN(parsed)) return;
            setType(parsed);
          }}
          options={[
            {
              label: 'Score Average',
              value: LeaderboardType.Average,
            },
            {
              label: 'Score Count',
              value: LeaderboardType.Count,
            },
          ]}
        />
      </Form.Item>

      {type === LeaderboardType.Count && (
        <Col span={24}>
          <Form.Item label="Score lower limit" name="minimumscore" style={{ width: '50%', display: 'inline-block' }} tooltip="The lowest score to be counted, inclusive">
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item label="Score upper limit" name="maximumscore" style={{ width: '50%', display: 'inline-block' }} tooltip="The highest score to be counted, inclusive">
            <InputNumber min={0} />
          </Form.Item>
        </Col>
      )}

      <Col span={12}>
        <Form.Item label="Minimum responses required" name="minimumresponses" tooltip="The number of responses required for an agent to show on the leaderboard">
          <InputNumber min={0} />
        </Form.Item>
      </Col>

      <Collapse>
        <Panel header="Filters" key="1">
          {QueueSelectionComponent(t, queueList, selectedQueues, setSelectedQueues)}
          {QuestionSelectionComponent(t, questionList, selectedQuestions, setSelectedQuestions)}
        </Panel>
      </Collapse>
    </div>
  );
}
