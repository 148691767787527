import { DownloadOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Space, Tooltip } from 'antd';
import { Axios } from 'axios';
import { Parser } from 'json2csv';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import DataTable from '../components/DataTable';
import DateSelector from '../components/DateSelector';
import useInterval from '../hooks/useInterval';
import { GetSurveyResponses } from '../scripts/API/APICalls';
import { RawSurveyResponse } from '../scripts/API/APITypes';
import { default as debugLog, default as debuglog } from '../scripts/debugLog';

export interface SurveyQuestion {
  questionNumber: string;
  questionText: string;
  responses: string[];
}

interface TableSurveyResponseModel {
  OriginalContactId: string;
  AgentName: string;
  QueueName: string;
  InitiationMethod: string;
  QuestionNumber: number;
  Question: string;
  Transcript: string;
  TimeAnswered: string;
}

function TablePage(props: { axiosInstance: Axios; connectInstanceARN: string }) {
  const [fetchingData, setFetchingData] = useState<boolean>(true);
  const [responsesSource, setResponsesSource] = useState<RawSurveyResponse[]>([]);
  const [tableSource, setTableSource] = useState<TableSurveyResponseModel[]>([]);

  useEffect(() => {
    async function getResponses() {
      await fetchResponses();
    }

    getResponses();
    return;
  }, []);

  async function fetchResponses() {
    await setFetchingData(true);

    // timeRange = [Moment, Moment]
    let start, end: number;

    if (timeRange[0] && timeRange[1] && !daily) {
      // Use time range if it exists:
      start = timeRange[0].valueOf();
      end = timeRange[1].valueOf();
    } else {
      // Otherwise use midnight to now:
      const midnight = new Date();
      midnight.setHours(0, 0, 0, 0);
      start = midnight.valueOf();
      end = new Date().valueOf();
    }

    const results = await GetSurveyResponses(props.axiosInstance, props.connectInstanceARN, start, end);
    const newTableSource: TableSurveyResponseModel[] = [];

    results.forEach((r) => {
      if (r.QuestionNumber <= 0) return;

      // Strip <speak></speak> tags from responses:
      r.Question = r.Question?.replace(/<speak>|<\/speak>/g, '') ?? '';
      if (r.Transcript === 'Timeout') r.Transcript = '-';

      newTableSource.push({
        OriginalContactId: r.OriginalContactId,
        AgentName: `${r.AgentFirstName} ${r.AgentLastName}`,
        QueueName: r.QueueName,
        InitiationMethod: r.InitiationMethod,
        QuestionNumber: r.QuestionNumber,
        Question: r.Question,
        Transcript: r.Transcript,
        TimeAnswered: r.TimeAnswered,
      });
    });

    await setResponsesSource(results);
    await setTableSource(newTableSource);
    await setFetchingData(false);
  }

  function condenseUUID(id: string): string {
    if (!id) return '';
    return id.slice(0, 4) + ' ... ' + id.slice(id.length - 4, id.length);
  }

  function formatDateTime(datetime: string): string {
    if (!datetime) return '';
    const d = DateTime.fromISO(datetime);
    return d.toLocaleString(DateTime.DATE_SHORT) + ' ' + d.toLocaleString(DateTime.TIME_24_WITH_SECONDS);
  }

  function capitaliseString(string: string): string {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  //(value: string) => `${value} (avg)`
  function aggregateNumberResponse(leafValues: any, _aggregatedValues: any): string {
    let avg = 0;
    let count = 0;

    for (const transcript of leafValues) {
      if (transcript.length === 0 || isNaN(transcript)) continue;
      avg += parseInt(transcript);
      count++;
    }

    debuglog('avg:', avg, 'count:', count);

    if (count > 0) {
      return `≈${Math.round((avg / count) * 100) / 100} (${count})`;
    } else {
      return '';
    }
  }

  const custCols = useMemo(
    () => [
      {
        Header: 'Contact ID',
        accessor: 'OriginalContactId',
        Cell: ({ row }: any) => {
          return (
            <Tooltip placement="topLeft" title={row.values['OriginalContactId']}>
              <span>{condenseUUID(row.values['OriginalContactId'])}</span>
            </Tooltip>
          );
        },
      },
      {
        Header: 'Agent',
        accessor: 'AgentName',
      },
      {
        Header: 'Queue',
        accessor: 'QueueName',
      },
      {
        Header: 'Initiation Method',
        accessor: 'InitiationMethod',
        Cell: ({ row }: any) => {
          return <span>{capitaliseString(row.values['InitiationMethod'])}</span>;
        },
      },
      {
        Header: 'Q #',
        accessor: 'QuestionNumber',
      },
      {
        Header: 'Question',
        accessor: 'Question',
        canGroupBy: false,
      },
      {
        Header: 'Response',
        accessor: 'Transcript',
        canGroupBy: false,
        aggregate: aggregateNumberResponse,
      },
      {
        Header: 'Date Answered',
        accessor: 'TimeAnswered',
        canGroupBy: false,
        Cell: ({ row }: any) => {
          return <span>{formatDateTime(row.values['TimeAnswered'])}</span>;
        },
      },
    ],
    [],
  );

  /**
   * Time Range
   */

  const [daily, setDaily] = useState<boolean>(true);
  const [timeRange, setTimeRange] = useState<[any, any]>([undefined, undefined]);

  /**
   * CSV Download
   */

  function formatItem(item: RawSurveyResponse) {
    return {
      ContactId: item.ContactId,
      QuestionNumber: item.QuestionNumber,
      AgentFirstName: item.AgentFirstName,
      AgentId: item.AgentId,
      AgentLastName: item.AgentLastName,
      InitiationMethod: item.InitiationMethod,
      OriginalContactId: item.OriginalContactId,
      Question: item.Question,
      QuestionSet: item.QuestionSet,
      QueueName: item.QueueName,
      S3URL: item.S3URL,
      StartFragmentNumber: item.StartFragmentNumber,
      StartTimestamp: item.StartTimestamp,
      StopTimestamp: item.StopTimestamp,
      StreamARN: item.StreamARN,
      TimeAnswered: formatDateTime(item.TimeAnswered),
      Transcript: item.Transcript,
    };
  }

  function DownloadCurrentData() {
    let csvString = '';

    try {
      const parser = new Parser({
        fields: [
          { label: 'TimeAnswered', value: 'TimeAnswered' },
          { label: 'ContactId', value: 'ContactId' },
          { label: 'OriginalContactId', value: 'OriginalContactId' },
          { label: 'QuestionNumber', value: 'QuestionNumber' },
          { label: 'AgentFirstName', value: 'AgentFirstName' },
          { label: 'AgentId', value: 'AgentId' },
          { label: 'AgentLastName', value: 'AgentLastName' },
          { label: 'InitiationMethod', value: 'InitiationMethod' },
          { label: 'Question', value: 'Question' },
          { label: 'QuestionSet', value: 'QuestionSet' },
          { label: 'QueueName', value: 'QueueName' },
          { label: 'S3URL', value: 'S3URL' },
          { label: 'Response', value: 'Transcript' },
        ],
        transforms: [formatItem],
      });

      // Remove 0 and -1 questions:
      const filteredResponses = responsesSource.filter((value) => value.QuestionNumber !== 0 && value.QuestionNumber !== -1);

      csvString = parser.parse(filteredResponses);
    } catch (e) {
      debugLog(e);
    }

    const dateNow = new Date(Date.now());
    const dateString = `${dateNow.getFullYear()}_${dateNow.getMonth().toString().padStart(2, '0')}_${dateNow.getDate().toString().padStart(2, '0')}-${dateNow
      .getHours()
      .toString()
      .padStart(2, '0')}_${dateNow.getMinutes().toString().padStart(2, '0')}_${dateNow.getSeconds().toString().padStart(2, '0')}`;

    let dataUri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString);

    let exportFileDefaultName = `PCSExport_${dateString}.csv`;

    let linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
    linkElement.remove();
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: '8px' }}>
          <Tooltip title="Refresh Data" placement="right">
            {<Button icon={<ReloadOutlined />} onClick={fetchResponses} loading={fetchingData} disabled={fetchingData} />}
          </Tooltip>
          <DateSelector onDateChanged={fetchResponses} daily={daily} setDaily={setDaily} timeRange={timeRange} setTimeRange={setTimeRange} />
        </div>

        <Space direction="horizontal" align="center">
          <Button onClick={DownloadCurrentData}>
            <DownloadOutlined /> Download as CSV
          </Button>
        </Space>
      </div>

      <DataTable data={tableSource} columns={custCols} fetchingData={fetchingData} />
    </div>
  );
}

export default TablePage;
