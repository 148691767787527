import { CheckOutlined, EditOutlined, ExpandOutlined, PlusCircleOutlined, ReloadOutlined, UndoOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';
import { Axios } from 'axios';
import { useEffect, useState } from 'react';
import GridLayout from 'react-grid-layout';
import DateSelector from '../components/DateSelector';
import WidgetGrid from '../components/WidgetGrid';
import { WidgetConfig, WidgetType } from '../components/widgets/common/WidgetTypes';
import { CompletionWidgetConfig } from '../components/widgets/completion/CompletionWidget';
import { CountType, CountWidgetConfig } from '../components/widgets/count/CountWidget';
import { LeaderboardWidgetConfig } from '../components/widgets/leaderboard/LeaderboardWidget';
import { LeaderboardType } from '../components/widgets/leaderboard/LeaderboardWidgetSettings';
import { ScoreWidgetConfig } from '../components/widgets/score/ScoreWidget';
import useInterval from '../hooks/useInterval';
import AddWidgetModal from '../modals/AddWidgetModal';
import EditWidgetModal from '../modals/EditWidgetModal';
import { GetSurveyResponses } from '../scripts/API/APICalls';
import { AgentDataModel, QuestionDataModel, QueueDataModel, RawSurveyResponse } from '../scripts/API/APITypes';
import { default as debuglog } from '../scripts/debugLog';

export interface SurveyQuestion {
  questionNumber: string;
  questionText: string;
  responses: string[];
}

function DashboardPage(props: { axiosInstance: Axios; connectInstanceARN: string }) {
  const [fetchingData, setFetchingData] = useState<boolean>(true);
  const [responsesSource, setResponsesSource] = useState<RawSurveyResponse[]>([]);

  useEffect(() => {
    async function getResponses() {
      await fetchResponses();
    }

    getSkinning();
    getResponses();
    return;
  }, []);

  async function fetchResponses() {
    await setFetchingData(true);

    // timeRange = [Moment, Moment]
    let start, end: number;

    if (timeRange[0] && timeRange[1] && !daily) {
      // Use time range if it exists:
      start = timeRange[0].valueOf();
      end = timeRange[1].valueOf();
    } else {
      // Otherwise use midnight to now:
      const midnight = new Date();
      midnight.setHours(0, 0, 0, 0);
      start = midnight.valueOf();
      end = new Date().valueOf();
    }

    const results = await GetSurveyResponses(props.axiosInstance, props.connectInstanceARN, start, end);

    // Process returned results:
    processResults(results);

    await setResponsesSource(results);
    await setFetchingData(false);
  }

  /**
   * Selectable Lists
   */

  const [queueList, setQueueList] = useState<{ [key: string]: QueueDataModel }>({});
  const [questionList, setQuestionList] = useState<{ [key: string]: QuestionDataModel }>({});
  const [agentList, setAgentList] = useState<{ [key: string]: AgentDataModel }>({});

  function processResults(results: RawSurveyResponse[]) {
    const newQueueList: { [key: string]: QueueDataModel } = {},
      newQuestionList: { [key: string]: QuestionDataModel } = {},
      newAgentList: { [key: string]: AgentDataModel } = {};

    for (const res of results) {
      if (res.QuestionNumber <= 0) continue;

      // Strip <speak></speak> tags from responses:
      res.Question = res.Question?.replace(/<speak>|<\/speak>/g, '') ?? '';

      // Add queues:
      if (res.QueueARN && !newQueueList[res.QueueARN])
        newQueueList[res.QueueARN] = {
          arn: res.QueueARN,
          name: res.QueueName,
        };

      // Add question:
      if (!newQuestionList[res.QuestionNumber])
        newQuestionList[res.QuestionNumber] = {
          number: res.QuestionNumber,
          question: res.Question,
          set: res.QuestionSet,
        };

      // Add agents:
      if (!newAgentList[res.AgentId])
        newAgentList[res.AgentId] = {
          id: res.AgentId,
          firstName: res.AgentFirstName,
          lastName: res.AgentLastName,
        };
    }

    debuglog('Lists recalculated:');
    debuglog(' - Queues:', newQueueList);
    debuglog(' - Qtions:', newQuestionList);
    debuglog(' - Agents:', newAgentList);

    setQueueList(newQueueList);
    setQuestionList(newQuestionList);
    setAgentList(newAgentList);
  }

  /**
   * Auto Refresh
   */
  useInterval(async (): Promise<void> => {
    fetchResponses();
  }, 5000);

  /**
   * Time Range
   */

  const [daily, setDaily] = useState<boolean>(true);
  const [timeRange, setTimeRange] = useState<[any, any]>([undefined, undefined]);

  /**
   * Edit Widget Grid
   */

  const [editing, setEditing] = useState(false);

  async function onEditClicked(): Promise<void> {
    if (editing) {
      // Loop through each widget and grab current queues / agents:
      await storeSkinning({
        layout: layout,
        widgetConfigs: widgetConfigs,
      });
    }
    await setEditing(!editing);
  }

  // Widget keys available for queue data metrics:
  const defaultWidgetConfigs: WidgetConfig[] = [
    {
      type: WidgetType.Count,
      widgetid: '1',
      config: {
        label: 'Total Responses',
        countType: CountType.Completed,
        queues: [],
        questions: [],
        agents: [],
        amberThreshold: 2,
        redThreshold: 3,
        showAsGreen: false,
        amberSound: '',
        redSound: '',
        blink: false,
        sla: '',
      } as CountWidgetConfig,
    },
    {
      type: WidgetType.Score,
      widgetid: '2',
      config: {
        label: 'Average Score',
        queues: [],
        questions: [],
        agents: [],
        amberThreshold: 2,
        redThreshold: 3,
        showAsGreen: false,
        amberSound: '',
        redSound: '',
        blink: false,
        sla: '',
      } as ScoreWidgetConfig,
    },
    {
      type: WidgetType.Leaderboard,
      widgetid: '3',
      config: { label: 'Agent Leaderboard', type: LeaderboardType.Average, queues: [], questions: [], minimumResponses: 0 } as LeaderboardWidgetConfig,
    },
    {
      type: WidgetType.Completion,
      widgetid: '4',
      config: { label: 'Completion Rate', queues: [], amberThreshold: 25, redThreshold: 10, amberSound: '', redSound: '', blink: false } as CompletionWidgetConfig,
    },
  ];

  const defaultLayout: GridLayout.Layout[] = [
    { i: '1', x: 0, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
    { i: '2', x: 2, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
    { i: '3', x: 4, y: 0, w: 4, h: 8, minW: 4, minH: 4 },
    { i: '4', x: 0, y: 2, w: 4, h: 6, minW: 4, minH: 4 },
  ];

  const [widgetConfigs, setWidgetConfigs] = useState<WidgetConfig[]>(defaultWidgetConfigs);
  const [layout, setLayout] = useState<GridLayout.Layout[]>(defaultLayout);

  function onLayoutChange(layout: GridLayout.Layout[]): void {
    setLayout(layout);
    debuglog('React (WidgetGrid.tsx): onLayoutChange: ', layout);
  }

  async function onResetLayoutClicked(): Promise<void> {
    await setWidgetConfigs(defaultWidgetConfigs);
    await setLayout(defaultLayout);
  }

  const [editWidgetVisible, setEditWidgetVisible] = useState(false);
  const [currentWidget, setCurrentWidget] = useState<WidgetConfig | null>(null);

  function openEditWidgetModal(widgetid: string): void {
    const index = widgetConfigs.findIndex((w) => w.widgetid === widgetid);

    setCurrentWidget(widgetConfigs[index]);

    debuglog('React (App.tsx): Editing widget', widgetid, widgetConfigs[index]);

    setEditWidgetVisible(true);
  }

  async function onEditWidgetSave(config: WidgetConfig): Promise<void> {
    const newConfigs = widgetConfigs;
    const index = widgetConfigs.findIndex((w) => w === currentWidget);
    newConfigs[index] = config;

    await setWidgetConfigs(newConfigs);

    debuglog('React (App.tsx): Widget configs updated', widgetConfigs);

    // Close modal:
    setEditWidgetVisible(false);
  }

  async function deleteWidget(widgetid: string | null): Promise<void> {
    if (!widgetid) return;

    const newConfigs = widgetConfigs;
    const index = widgetConfigs.findIndex((w) => w.widgetid === widgetid);

    debuglog('React (App.tsx): Deleting widget', newConfigs[index]);
    newConfigs.splice(index, 1);

    debuglog('React (App.tsx): Deleting widget', newConfigs);

    await setWidgetConfigs(newConfigs);

    // Close modal:
    setEditWidgetVisible(false);
  }

  /**
   * Add Widgets
   */

  const [addWidgetVisible, setAddWidgetVisible] = useState(false);

  async function addWidget(config: WidgetConfig): Promise<void> {
    const newConfigs = widgetConfigs;

    debuglog('React (App.tsx): Adding item', config);

    newConfigs.push(config);
    await setWidgetConfigs(newConfigs);

    setAddWidgetVisible(false);
  }

  /**
   * Skinning Store
   */

  async function storeSkinning(data: any): Promise<void> {
    window.localStorage.setItem('rxpcs-skinning', JSON.stringify(data));
    debuglog('React (App.tsx): Stored skinning', data);
  }

  async function getSkinning(): Promise<void> {
    const res = window.localStorage.getItem('rxpcs-skinning');
    if (!res) return;

    const skinning = JSON.parse(res);
    debuglog('React (App.tsx): Retrieved skinning', skinning);

    await applySkinning(skinning);
  }

  async function applySkinning(layoutJson: any): Promise<void> {
    if (layoutJson.widgetConfigs && layoutJson.widgetConfigs.length > 0) {
      // sort layout items so they appear in order
      const newConfigs = await layoutJson.layout
        .sort((a: any, b: any) => {
          // same row, compare X
          if (a.y === b.y) {
            return a.x - b.x;
          }

          // different row, compare Y
          return a.y - b.y;
        })
        .map((item: any) => {
          const cfg = layoutJson.widgetConfigs.find((wc: WidgetConfig) => {
            return wc.widgetid === item.i;
          });
          return cfg;
        });

      await setWidgetConfigs(newConfigs);
    } else {
      await setWidgetConfigs(defaultWidgetConfigs);
    }

    if (layoutJson.layout) {
      await setLayout(layoutJson.layout);
    } else {
      await setLayout(defaultLayout);
    }

    debuglog('React (App.tsx): Applied skinning', layoutJson);
  }

  /**
   * Fullscreen
   */

  const [fs, setFs] = useState<boolean>(false);

  useEffect(() => {
    document.addEventListener('fullscreenchange', (event) => {
      if (document.fullscreenElement) setFs(true);
      else setFs(false);
    });
  }, []);

  return (
    <div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', gap: '8px' }}>
        <div style={{ display: 'flex', gap: '8px' }}>
          <Tooltip title="Refresh Data" placement="right">
            {<Button icon={<ReloadOutlined />} onClick={fetchResponses} loading={fetchingData} disabled={fetchingData} />}
          </Tooltip>
          <DateSelector onDateChanged={fetchResponses} daily={daily} setDaily={setDaily} timeRange={timeRange} setTimeRange={setTimeRange} />
        </div>
        <div style={{ display: 'flex', gap: '8px' }}>
          {editing ? (
            <>
              <Popconfirm key="deleteconfirm" title="Are you sure?" okText="Yes" cancelText="Cancel" onConfirm={onResetLayoutClicked} icon={<WarningOutlined style={{ color: 'red' }} />}>
                <Button>
                  <UndoOutlined />
                  Reset Layout
                </Button>
              </Popconfirm>
              <Button onClick={(): void => setAddWidgetVisible(true)}>
                <PlusCircleOutlined />
                Add Widget
              </Button>
              <Button type="primary" onClick={onEditClicked}>
                <CheckOutlined />
                Finish Editing
              </Button>
            </>
          ) : (
            <>
              <Button onClick={onEditClicked}>
                <EditOutlined />
                Edit Layout
              </Button>
            </>
          )}
          <Button
            onClick={() => {
              !fs ? document.body.requestFullscreen() : document.exitFullscreen();
            }}
          >
            <ExpandOutlined />
            {!fs ? 'Fullscreen' : 'Exit'}
          </Button>
        </div>
      </div>

      <WidgetGrid
        widgetConfigs={widgetConfigs}
        layout={layout}
        onLayoutChange={onLayoutChange}
        responseData={responsesSource}
        editing={editing}
        onEditWidgetClicked={openEditWidgetModal}
        queueList={[]}
        currentQueues={[]}
        queueSelected={function (queue: string): void {
          throw new Error('Function not implemented.');
        }}
        initComplete={true}
      />

      <EditWidgetModal
        currentWidget={currentWidget}
        isVisible={editWidgetVisible}
        handleOk={onEditWidgetSave}
        handleCancel={(): void => {
          setEditWidgetVisible(false);
        }}
        handleDelete={(): void => {
          deleteWidget(currentWidget?.widgetid ?? null);
        }}
        queueList={queueList}
        questionList={questionList}
        agentList={agentList}
        availableAlarms={[]}
        openAlarmModal={() => {}}
        availableAgents={[]}
      />

      <AddWidgetModal
        isVisible={addWidgetVisible}
        handleOk={addWidget}
        handleCancel={(): void => {
          setAddWidgetVisible(false);
        }}
        queueList={queueList}
        questionList={questionList}
        agentList={agentList}
        widgetConfigs={widgetConfigs}
        availableAlarms={[]}
        openAlarmModal={() => {}}
        availableAgents={[]}
      />
    </div>
  );
}

export default DashboardPage;
