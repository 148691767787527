export enum WidgetType {
  Count,
  Score,
  Leaderboard,
  Completion,
}

export interface WidgetConfig {
  widgetid: string;
  type: WidgetType;
  config: WidgetDataConfig;
}

export interface WidgetDataConfig {
  label: string;
}
